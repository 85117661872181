import '../global.css'

import React from 'react'
import Head from 'next/head'
import {useRouter} from 'next/router'
import Script from 'next/script'

import {getBrandOverrideFromCookie} from '@daedalus/core/src/_web/brand/business/persistData'
import {getBrandCodeFromUrlOrCookie} from '@daedalus/core/src/_web/brand/utils'
import {defaultBrand, getBrandByCode} from '@daedalus/core/src/brand/config'

import {PageProps as IndexPageProps} from './[[...slug]]'

import type {AppProps} from 'next/app'

/**
 * Could be pageProps from any page
 */
type AllPageProps = IndexPageProps | undefined

const App = ({Component, pageProps}: AppProps<AllPageProps>) => {
  // Note: this will be empty on initial render so we'll always render whatever the pageProps determines first, only use this for development/debugging
  const router = useRouter()
  const brandOverrideCode =
    router.query.brandOverride || getBrandOverrideFromCookie()
  const brandOverride =
    typeof brandOverrideCode === 'string' && getBrandByCode(brandOverrideCode)

  let brand = brandOverride || pageProps.brand
  if (!brand) {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line fp/no-mutation
      brand = getBrandByCode(getBrandCodeFromUrlOrCookie(window._env.APP_ENV))
    } else {
      brand = defaultBrand
    }
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <link rel="icon" href={`/${brand.code}_favicon.png`} sizes="any" />
        <title>{`${brand.name} | ${brand.tagline}`}</title>
        <meta
          name="description"
          content={brand.metaDescription}
          data-react-helmet="true"
        />
        <meta property="og:title" content={brand.name} />
        <meta property="og:description" content={brand.metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`/${brand.code}.png`} />
        <meta property="og:url" content={`https://${brand.address}`} />

        {brand.meta &&
          Object.entries(brand.meta).map(([key, value]) => (
            <meta key={key} name={key} content={value} />
          ))}
        <link rel="stylesheet" href={`/assets/css/${brand.theme}.css`} />
      </Head>

      <Component {...pageProps} />

      {brand.cookieProId ? (
        <>
          <Script
            type="text/javascript"
            src={`https://cookie-cdn.cookiepro.com/consent/${brand.cookieProId}/OtAutoBlock.js`}
          ></Script>
          <Script
            src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
            data-document-language="true"
            type="text/javascript"
            charSet="UTF-8"
            data-domain-script={brand.cookieProId}
          ></Script>
          <Script id="cookie-pro">
            {`
              function OptanonWrapper() {
                window.COOKIE_PRO_LOADED = true
                let event
                if (typeof window.CustomEvent === 'function') {
                  event = new CustomEvent('cookieProLoaded')
                } else {
                  event = document.createEvent('CustomEvent')
                  event.initCustomEvent('cookieProLoaded', true, false, null)
                }
                window.dispatchEvent(event)
              }
          `}
          </Script>
        </>
      ) : null}
    </>
  )
}

export default App
